import { dateToISOString } from '../utils/date';
import BUILD_PARAMS from '../utils/build';
import { getVar } from '../utils/url';
import { dateReviver } from '../utils/objects';


const SEDI_API_URL = BUILD_PARAMS.apiUrl;
const SEDI_API_KEY = getVar('apikey') || BUILD_PARAMS.apiKey;
const SEDI_USER_KEY = getVar('userkey');
const SEDI_API_LANG = getVar('lang') || 'ru';


function getApiHost() {
  let apiHost = SEDI_API_URL;
  if (window && window.sedi$ && window.sedi$.sedi && window.sedi$.sedi.host) {
    apiHost = window.sedi$.sedi.host;
  }

  return apiHost;
}


function invokeApi(apiMethod, body) {
  let formData = new URLSearchParams();
  formData.set('frontend_version', 'react');
  formData.set("q", apiMethod);
  formData.set("dateformat", "ISO");
  if (SEDI_API_KEY) {
    formData.set("apikey", SEDI_API_KEY);
  }
  if (SEDI_USER_KEY) {
    formData.set("userkey", SEDI_USER_KEY);
  }

  if (body) {
    for (const paramName in body) {
      let paramValue = body[paramName];

      if (paramValue !== undefined) {
        if (paramValue instanceof Date) {
          paramValue = dateToISOString(paramValue);
        }

        formData.set(paramName, paramValue);
      }
    }
  }

  let apiHost = getApiHost();

  return fetch(apiHost + "/webapi", {
    credentials: 'include',
    method: 'POST',
    body: formData
  })
    // ! .then(res => res.json()) // не понимает даты в формате Microsoft
    // ! поэтому используется reviver
    .then(body => body.text())
    .then(text => JSON.parse(text, dateReviver))
    .then(
      (response) => {
        console.log("SEDI API " + apiMethod, { request: formData, response: response });

        return response;
      },
      // ! Note: it's important to handle errors here
      // ! instead of a catch() block so that we don't swallow
      // ! exceptions from actual bugs in components.
      (error) => {
        console.warn("SEDI API ERROR " + apiMethod, error);

        return {
          Success: false,
          Message: error.toString()
        }
      }
    );
}


function sendDebugInfo(messageOrObject) {
  let formData = new URLSearchParams();
  formData.set("msg", typeof messageOrObject === "string" ? messageOrObject : JSON.stringify(messageOrObject));
  formData.set("type", "react_debug");

  let apiHost = getApiHost();

  return fetch(apiHost + "/handlers/jserror.ashx", {
    credentials: 'include',
    method: 'POST',
    body: formData
  });
}


export {
  SEDI_API_URL,
  SEDI_API_KEY,
  SEDI_API_LANG,
  invokeApi,
  sendDebugInfo
}