// GET

interface StringDictionary {
    [index: string]: string;
}


const searchParts = document.location.search.substr(1).split("&");
let flashVars: StringDictionary = {};

for (let index = 0; index < searchParts.length; index++) {
    const currentPart = searchParts[index].split('='),
        paramName = currentPart[0].replace("amp;", "").toLocaleLowerCase(),
        paramValue = decodeURIComponent(currentPart[1] || '');

    flashVars[paramName] = paramValue;
}

export function getVar(paramName: string) {
    const paramNameLowerCase = paramName.toLocaleLowerCase();

    return flashVars[paramNameLowerCase];
}


// HASH

const hashParts = document.location.hash.substr(1).split("&");
let hashVars: StringDictionary = {};

for (let index = 0; index < hashParts.length; index++) {
    const currentPart = hashParts[index].split('='),
        paramName = currentPart[0].replace("amp;", "").toLocaleLowerCase(),
        paramValue = decodeURIComponent(currentPart[1] || '');

    hashVars[paramName] = paramValue;
}

export function getHashVar(paramName: string) {
    const paramNameLowerCase = paramName.toLocaleLowerCase();

    return hashVars[paramNameLowerCase];
}

// URL

export const getCurrentUrl = () => {
    return window.location.href;
}


export const replaceHash = (sourceUrl: string, hash: string) => {
    if (hash.startsWith('#') === false){
        hash = '#' + hash;
    }

    let newUrl = sourceUrl;
    const hashIndex = newUrl.indexOf('#');
    if (hashIndex >= 0){
        newUrl = newUrl.substring(0, hashIndex);
    }
    newUrl = newUrl + hash;

    return newUrl;
}