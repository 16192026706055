//import 'bootstrap/dist/css/bootstrap.css';
import registerServiceWorker from './registerServiceWorker';
import createReactWidget, { unmountComponentAtNode } from "./utils/createReactWidget";

window.sediReact = {
    createReactWidget,
    unmountComponentAtNode
};

registerServiceWorker();

createReactWidget("placeholder", "IonicTaxi");
// createReactWidget("placeholder", "WidgetAutocomplete", {debug: true, lat: 59.932306, lon: 30.349017, radius: 200, placeholder: 'Санкт-Петербург', className: 'mb-5', inputClassName: 'form-control', inputClassNameLoading: 'form-control'});
// createReactWidget("placeholder2", "WidgetAutocomplete", {debug: true, lat: 55.753215, lon: 37.622504, radius: 200, placeholder: 'Москва', className: 'mb-5', inputClassName: 'form-control', inputClassNameLoading: 'form-control'});
// createReactWidget("placeholder3", "WidgetAutocomplete", {debug: true, lat: 43.003852, lon: 41.019151, radius: 200, placeholder: 'Сухум', className: 'mb-5', inputClassName: 'form-control', inputClassNameLoading: 'form-control'});