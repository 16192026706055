import { invokeApiSetGroupPhones } from "../api/group/phones";

// ? реализовано получение телефонов из профиля текущего юзера
// TODO загрузку телефонов с сервера

//const REQUEST_GET_GROUP_PHONES_WAIT = 'REQUEST_GET_GROUP_PHONES_WAIT';
const REQUEST_GET_GROUP_PHONES_SUCCESS = 'REQUEST_GET_GROUP_PHONES_SUCCESS';
//const REQUEST_GET_GROUP_PHONES_FAIL = 'REQUEST_GET_GROUP_PHONES_FAIL';

const REQUEST_SET_GROUP_PHONES_WAIT = 'REQUEST_SET_GROUP_PHONES_WAIT';
const REQUEST_SET_GROUP_PHONES_SUCCESS = 'REQUEST_SET_GROUP_PHONES_SUCCESS';
const REQUEST_SET_GROUP_PHONES_FAIL = 'REQUEST_SET_GROUP_PHONES_FAIL';

// Reducer
const initialState = {
  phones: [],
  loading: false,
  error: false,
};


function reducerGroupPhones(state = initialState, action){
  switch (action.type) {
    // get
    // case REQUEST_GET_GROUP_PHONES_WAIT:
    //   return {
    //     regions: null,
    //     loading: true,
    //     error: false,
    //   };
    case REQUEST_GET_GROUP_PHONES_SUCCESS:
    case REQUEST_SET_GROUP_PHONES_SUCCESS:
      let newPhones = state.phones.filter(p => p.groupID !== action.payload.groupID);
      newPhones.push(action.payload);

      return {
        ...state,
        phones: newPhones,
        loading: false,
        error: false,
      };
    // case REQUEST_GET_GROUP_PHONES_FAIL:
    //   return {
    //     regions: null,
    //     loading: false,
    //     error: action.payload,
    //   };
    // set
    case REQUEST_SET_GROUP_PHONES_WAIT:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case REQUEST_SET_GROUP_PHONES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload
      };    
    default:
      return state;
  }
};


/* Получение телефонов */
// Action Creators
// function requestGetGroupPhones(){
//   return { type: REQUEST_GET_GROUP_PHONES_WAIT }
// };


function requestGetGroupPhonesSuccess({groupID, phones}){
  return { type: REQUEST_GET_GROUP_PHONES_SUCCESS, payload: {groupID, phones} }
};


// function requestGetGroupPhonesError(response){
//   return { type: REQUEST_GET_GROUP_PHONES_FAIL, payload: response }
// };


function fetchGroupPhonesByGroupID(groupID, currentUser){
  return function(dispatch){          
    let phones = [];
    
    const groupInfo = currentUser.getGroup(groupID);
    if (groupInfo) {
      phones = groupInfo.PhoneNumbers;
    }

    dispatch(requestGetGroupPhonesSuccess({groupID, phones}));
  }
};

/* Добавление регионов */
// Action Creators
function requestSetGroupPhones(){
  return { type: REQUEST_SET_GROUP_PHONES_WAIT }
};


function requestSetGroupPhonesSuccess({groupID, phones}){
  return { type: REQUEST_SET_GROUP_PHONES_SUCCESS, payload: {groupID, phones} }
};


function requestSetGroupPhonesError(response){
  return { type: REQUEST_SET_GROUP_PHONES_FAIL, payload: response }
};


function setGroupPhones(groupID, phones){  
  return function(dispatch){
    dispatch(requestSetGroupPhones());
    
    invokeApiSetGroupPhones(groupID, phones)
      .then(function(response){
        if (response.Success){
          dispatch(requestSetGroupPhonesSuccess({groupID, phones}));
        }
        else{
          dispatch(requestSetGroupPhonesError(response));
        }
      });
  }
}

export { 
  reducerGroupPhones, 
  fetchGroupPhonesByGroupID,
  setGroupPhones
};