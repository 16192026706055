import EnumEmployeeRole from './enums/EnumEmployeeRole';
import EnumRoleStatus from './enums/EnumRoleStatus';
import EnumEmployeeType from './enums/EnumEmployeeType';
import Balance from './Balance';


class UserInfo {
  constructor(source) {
    source = source || {};

    this.ID = parseInt(source.ID) || -1;
    this.AccountID = parseInt(source.AccountID) || -1;
    this.Name = source.Name || "Anonymous";
    this.SecondName = source.SecondName || "";
    this.Login = source.Login || "";
    this.Email = source.Email || "";
    this.UserType = source.UserType || "Unknown";
    this.IsAnonymous = source.IsAnonymous === false ? false : true;
    this.IsEmployee = !!source.IsEmployee;
    this.Groups = source.Groups || [];
    this.Roles = source.Roles || [];
    this.Phones = source.Phones || [];
    this.Addresses = source.Addresses || [];
    this.Promocode = parseInt(source.Promocode) || -1;
    this.Birthday = new Date(source.Birthday) || new Date();
    this.EmployeeType = source.EmployeeType || "";
    this.Owner = source.Owner || {};
    this.Balance = new Balance(source.Balance);
  }


  /**
   * Ищет группы в которые есть роли у сотрудника
   * @param {EnumEmployeeRole[]} roles 
   * @returns {number[]} ID групп
   */
  getGroupsByRoles(roles) {
    const groupIDs = this.Roles
      .filter(r => r.Allowed && (!roles || roles.includes(r.ID)))
      .map(r => r.GroupID)
      .sort();

    let uniqueGroupIDs = [...new Set(groupIDs)];

    return uniqueGroupIDs;
  }


  getGroup(groupID) {
    return this.Groups.find(g => g.ID === groupID);
  }


  /**
   * проверят, что это водитель ждущий разрешение на работу
   * @returns {boolean} результат
   */
  isDriverWaitingApproval() {
    return this.Roles
      .some(r => !r.Allowed && r.ID === EnumEmployeeRole.TaxiDrivers && r.Status === EnumRoleStatus.WaitingApproval);
  }


  /**
   * проверят, что это водитель, которые еще не отправил заявку на разрешение на работу
   * @returns {boolean} результат
   */
  isDriverNew() {
    return this.Roles
      .some(r => !r.Allowed && r.ID === EnumEmployeeRole.TaxiDrivers && r.Status === EnumRoleStatus.New);
  }


  /**
   * проверят, что это водитель, который еще не получил доступ (есть роль водителя, но неактивная)
   * @returns {boolean} результат
   */
  isDriverWithoutAccess() {
    return this.isDriverWithAccess();
  }


  /**
   * проверят, что это водитель, который уже получил доступ
   * @returns {boolean} результат
   */
  isDriverWithAccess() {
    return this.Roles
      .some(r => r.Allowed && r.ID === EnumEmployeeRole.TaxiDrivers);
  }


  isPremium() {
    return this.EmployeeType === EnumEmployeeType.Premium;
  }


  getPhonesByType(phoneType) {
    return this.Phones.filter(p => p.Type === phoneType);
  }


  getCurrency() {
    return this.Owner.Currency || '';
  }
}

export default UserInfo;