
export function getJsonFromLocalStorage(storageKey) {
    try {
        return JSON.parse(localStorage.getItem(storageKey));
    }
    catch (ex) {
        // catch exception
    }
}


export function setItemToLocalStorage(storageKey, data) {
    try {
        return localStorage.setItem(storageKey, JSON.stringify(data));
    }
    catch (ex) {
        // catch exception
    }
}



export function removeItemFromLocalStorage(storageKey) {
    try {
        return localStorage.removeItem(storageKey);
    }
    catch (ex) {
        // catch exception
    }
}