const { 
    REACT_APP_BUILD_PLATFORM,
    REACT_APP_API_KEY_ANDROID,
    REACT_APP_API_KEY_IOS,
    REACT_APP_API_URL
} = process.env;


const forIOS = REACT_APP_BUILD_PLATFORM === 'IOS';
const forAndroid = REACT_APP_BUILD_PLATFORM === 'ANDROID';

let apiKey = '';
if (forIOS){
    apiKey = REACT_APP_API_KEY_IOS || '';
}
else if (forAndroid){
    apiKey = REACT_APP_API_KEY_ANDROID || '';
}

const BUILD_PARAMS = {
    forIOS,
    forAndroid,
    apiKey,
    apiUrl: REACT_APP_API_URL
}

export default BUILD_PARAMS;