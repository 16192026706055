import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FA from 'react-fontawesome';

const propTypes = {
  name: PropTypes.string,
  fixedWidth: PropTypes.bool,
  space: PropTypes.bool,
  spin: PropTypes.bool,
  size: PropTypes.oneOf(["", "lg", "2x", "3x", "4x", "5x"])
};

const defaultProps = {
  name: 'rocket',
  fixedWidth: null,
  space: true,
  spin: false,
  size: ""
};

class FaIcon extends Component {
  render() {
    let currentProps = {
      ...this.props
    };

    if (!currentProps.size){
      delete currentProps['size'];
    }    

    if (currentProps.space){
      currentProps.className = (currentProps.className || "") + " mr-1";
    }
    delete currentProps['space'];

    return <FA {...currentProps}/>;
  }
}

FaIcon.propTypes = propTypes;
FaIcon.defaultProps = defaultProps;

export default FaIcon;