import { invokeApi } from "../api";

export function invokeApiSetGroupPhones(groupID, newPhones) {
  let requestParams = { groupID };
  newPhones.forEach(function(phone, index){
    requestParams["phone" + index] = phone.Number;
    requestParams["phonetype" + index] = phone.Type;
  });

  return invokeApi("set_group_phones", requestParams);
}

