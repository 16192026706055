import { invokeApi } from "../api";

export function invokeApiGetCities(cityIDs, groupIDs, name) {
  return invokeApi("get_cities", { cityIDs, groupIDs, name });
}

export function invokeApiGetCitiesByGroupIDs(groupIDs) {
  return invokeApiGetCities(undefined, groupIDs, undefined);
}

export function invokeApiSetGroupCities(groupID, cityIDs) {
  return invokeApi("set_group_cities", { groupID, cityIDs });
}

