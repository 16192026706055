/**
 * конвертирует дату в формат ISO и вырезает время
 * @param {Date} date дата
 * @returns {string} дата в формате ISO 2011-10-05
 */
export function dateToISOStringWithoutTime(date) {
    return dateToISOString(date).substring(0, 10);
}


/**
 * конвертирует дату в формат ISO 
 * @param {Date} date дата
 * @returns {string} дата в формате ISO 2011-10-05T00:00:00.000
 */
export function dateToISOString(date) {
    return date.toISOString();
}


export function secondsToTimeAsString(value) {
    const secondsAsNumber = value % 60;
    const secondsAsString = padTimePart(secondsAsNumber);

    const minutesAsNumber = (value - secondsAsNumber) / 60;
    const minutesAsString = padTimePart(minutesAsNumber);

    return `${minutesAsString}:${secondsAsString}`;
}


function padTimePart(value) {
    if (value >= 10) {
        return value;
    }

    return `0${value}`;
}