import React, { Component } from 'react';
import FaSpinner from 'components/bricks/FaSpinner';
import store from "./../../store/store";
import { actionLogin, getUserInfoFromState, isInitialUserInfoFromLocalStorage } from '../../store/user';


export default function asyncComponent(importComponent) {
  class AsyncComponent extends Component {
    constructor(props) {
      super(props);
      this.state = {
        component: null
      };

      this.setStateESList = this.setStateESList.bind(this);
    }

    async componentDidMount() {
      // ! нужно вызвать раньше, чем другие компоненты начнут обращаться к api
      this.checkAuth();

      const { default: component } = await importComponent();

      this.setStateESList({
        component: component
      });      
    }

    /**
     * ? функция для обхода предупреждения от ESLint "Do not use setState in componentDidMount (react/no-did-mount-set-state)"
     * @param {object} state состояние
     */
    setStateESList(state) {
      this.setState(state);
    }


    checkAuth() {
      const stateInStore = store.getState();
      const user = getUserInfoFromState(stateInStore);

      if (!user || isInitialUserInfoFromLocalStorage(stateInStore)) {
          store.dispatch(actionLogin());
      }
    }


    render() {
      const LoadedComponent = this.state.component;

      return LoadedComponent ? <LoadedComponent {...this.props} /> : <FaSpinner />;
    }
  }


  return AsyncComponent;
}