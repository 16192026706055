import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import { reducerGroupRegions } from "./groupRegions";
import { reducerGroupPhones } from "./groupPhones";
import { reducerUser, ACTION_TYPE_SET_USER } from "./user";
import dynamicMiddlewares, { addMiddleware } from 'redux-dynamic-middlewares'


const sediLegacyJQuery = window.sedi$;
const sediBridgeInitialState = sediLegacyJQuery ? { jQuery: sediLegacyJQuery } : false;

function reducerSediBridge(state = sediBridgeInitialState, action) {
  switch (action.type) {
    case "SET_SEDI_BRIDGE":
      return action.sediBridge;
    default:
      return state;
  }
}

// CodeSplitting
// https://github.com/abettadapur/redux/blob/5a7259404d495d17c77454c8eb4048246222ffcf/docs/recipes/CodeSplitting.md

const staticReducers = {
  user: reducerUser,
  sediBridge: reducerSediBridge,
  groupRegions: reducerGroupRegions,
  groupPhones: reducerGroupPhones
};


// Configure the store
function configureStore() {
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(createReducer(), composeEnhancers(applyMiddleware(thunk, dynamicMiddlewares)));

  // Add a dictionary to keep track of the registered async reducers
  store.asyncReducers = {}

  // Create an inject reducer function
  // This function adds the async reducer, and creates a new combined reducer
  store.injectReducer = (key, asyncReducer, asyncMiddleware) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createReducer(store.asyncReducers))

    if (asyncMiddleware !== undefined) {
      addMiddleware(asyncMiddleware);
    }
  }

  // Return the modified store
  return store
}


function createReducer(asyncReducers) {
  return combineReducers({
    ...staticReducers,
    ...asyncReducers
  })
}


const store = configureStore();


if (sediBridgeInitialState) {
  setUserFromSediBridge(sediBridgeInitialState);
}


let currentUser;
let currentSediBridge;

function handleChange() {
  // user
  const previousUser = currentUser;
  const stateInStore = store.getState();

  currentUser = selectStoreUser(stateInStore)

  if (currentUser && previousUser !== currentUser) {
    changeUserInSediBridge(stateInStore, currentUser);
  }

  // sediBridge
  const previousSediBridge = currentSediBridge;
  currentSediBridge = getSediBridge(stateInStore);

  if (previousSediBridge !== currentSediBridge) {
    setUserFromSediBridge(currentSediBridge);
  }
}


function selectStoreUser(state) {
  return state.user && state.user.user;
}


// ! исправил проблему с обновлением UserInfo из react в старые виджеты на jQuery
function changeUserInSediBridge(state, newUser) {
  const sediBridge = getSediBridge(state);
  const setExternalUserInfo = sediBridge
    && sediBridge.jQuery
    && sediBridge.jQuery.sedi
    && sediBridge.jQuery.sedi.setExternalUserInfo;

  if (setExternalUserInfo) {
    setExternalUserInfo(newUser)
  }
}


function getSediBridge(state) {
  return state.sediBridge;
}



function setUserFromSediBridge(sediBridge) {
  const userFromSediBridge = sediBridge && sediBridge.jQuery && sediBridge.jQuery.sedi && sediBridge.jQuery.sedi.user;

  if (userFromSediBridge) {
    store.dispatch({
      type: ACTION_TYPE_SET_USER,
      payload: userFromSediBridge
    });
  }
}


store.subscribe(handleChange);


export default store;