class Balance {
  public AccountID: number;
  public Currency: string;
  public Value: number;
  public Locked: number;
  public Credit: number;

  public constructor(source: any){
    source = source || {};

    this.AccountID = source.AccountID || 0;
    this.Currency = source.Currency || '';
    this.Value = source.Value || 0;
    this.Locked = source.Locked || 0;
    this.Credit = source.Credit || 0;
  }
}

export default Balance;