import { invokeApiGetCitiesByGroupIDs, invokeApiSetGroupCities } from "../api/geo/cities";

const REQUEST_GET_GROUP_REGIONS_WAIT = 'REQUEST_GET_GROUP_REGIONS_WAIT';
const REQUEST_GET_GROUP_REGIONS_SUCCESS = 'REQUEST_GET_GROUP_REGIONS_SUCCESS';
const REQUEST_GET_GROUP_REGIONS_FAIL = 'REQUEST_GET_GROUP_REGIONS_FAIL';

const REQUEST_SET_GROUP_REGIONS_WAIT = 'REQUEST_SET_GROUP_REGIONS_WAIT';
const REQUEST_SET_GROUP_REGIONS_SUCCESS = 'REQUEST_SET_GROUP_REGIONS_SUCCESS';
const REQUEST_SET_GROUP_REGIONS_FAIL = 'REQUEST_SET_GROUP_REGIONS_FAIL';

const REQUEST_DELETE_GROUP_REGIONS_WAIT = 'REQUEST_DELETE_GROUP_REGIONS_WAIT';
const REQUEST_DELETE_GROUP_REGIONS_SUCCESS = 'REQUEST_DELETE_GROUP_REGIONS_SUCCESS';
const REQUEST_DELETE_GROUP_REGIONS_FAIL = 'REQUEST_DELETE_GROUP_REGIONS_FAIL';


// Reducer
const initialState = {
  regions: [],
  loading: false,
  error: false,
};


function reducerGroupRegions(state = initialState, action){
  switch (action.type) {
    // get
    case REQUEST_GET_GROUP_REGIONS_WAIT:
      return {
        regions: null,
        loading: true,
        error: false,
      };
    case REQUEST_GET_GROUP_REGIONS_SUCCESS:
      return {
        regions: action.payload,
        loading: false,
        error: false,
      };
    case REQUEST_GET_GROUP_REGIONS_FAIL:
      return {
        regions: null,
        loading: false,
        error: action.payload,
      };
    // set
    case REQUEST_SET_GROUP_REGIONS_WAIT:
      return {
        regions: state.regions,
        loading: true,
        error: false,
      };
    case REQUEST_SET_GROUP_REGIONS_SUCCESS:
      return {
        regions: action.payload,
        loading: false,
        error: false,
      };
    case REQUEST_SET_GROUP_REGIONS_FAIL:
      return {
        regions: state.regions,
        loading: false,
        error: action.payload,
      };
    // del
    case REQUEST_DELETE_GROUP_REGIONS_WAIT:
      return {
        regions: state.regions,
        loading: true,
        error: false,
      };
    case REQUEST_DELETE_GROUP_REGIONS_SUCCESS:
      return {
        regions: action.payload,
        loading: false,
        error: false,
      };
    case REQUEST_DELETE_GROUP_REGIONS_FAIL:
      return {
        regions: state.regions,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};


/* Получение регионов */
// Action Creators
function requestGetGroupRegions(){
  return { type: REQUEST_GET_GROUP_REGIONS_WAIT }
};


function requestGetGroupRegionsSuccess(response){
  return { type: REQUEST_GET_GROUP_REGIONS_SUCCESS, payload: response.Cities }
};


function requestGetGroupRegionsError(response){
  return { type: REQUEST_GET_GROUP_REGIONS_FAIL, payload: response }
};


function fetchGroupRegionsByGroupIDs(groupIDs){
  return function(dispatch){
    dispatch(requestGetGroupRegions());

    invokeApiGetCitiesByGroupIDs(groupIDs)
      .then(function(response){
        if (response.Success){
          dispatch(requestGetGroupRegionsSuccess(response));
        }
        else{
          dispatch(requestGetGroupRegionsError(response));
        }
      });
  }
};

/* Добавление регионов */
// Action Creators
function requestSetGroupRegions(){
  return { type: REQUEST_SET_GROUP_REGIONS_WAIT }
};


function requestSetGroupRegionsSuccess(newRegions){
  return { type: REQUEST_SET_GROUP_REGIONS_SUCCESS, payload: newRegions }
};


function requestSetGroupRegionsError(response){
  return { type: REQUEST_SET_GROUP_REGIONS_FAIL, payload: response }
};


function addGroupRegion(groupID, currentRegions, newRegion){
  if (currentRegions.some(r => r.ID === newRegion.ID)){
    // такой регион уже есть
    return () => {};
  }

  return function(dispatch){
    dispatch(requestSetGroupRegions());

    const currentRegionIDs = currentRegions.map(r => r.ID);
    const newRegionIDs = currentRegionIDs.concat([newRegion.ID]);
    
    invokeApiSetGroupCities(groupID, newRegionIDs)
      .then(function(response){
        if (response.Success){
          dispatch(requestSetGroupRegionsSuccess(currentRegions.concat([newRegion])));
        }
        else{
          dispatch(requestSetGroupRegionsError(response));
        }
      });
  }
}


/* Удаление регионов */
// Action Creators
function requestDeleteGroupRegions(){
  return { type: REQUEST_DELETE_GROUP_REGIONS_WAIT }
};


function requestDeleteGroupRegionsSuccess(newRegions){
  return { type: REQUEST_DELETE_GROUP_REGIONS_SUCCESS, payload: newRegions }
};


function requestDeleteGroupRegionsError(response){
  return { type: REQUEST_DELETE_GROUP_REGIONS_FAIL, payload: response }
};


function deleteGroupRegion(groupID, currentRegions, region){
  return function(dispatch){
    dispatch(requestDeleteGroupRegions());

    const newRegions = currentRegions.filter(r => r.ID !== region.ID);    
    const newRegionIDs = newRegions.map(r => r.ID);

    invokeApiSetGroupCities(groupID, newRegionIDs)
      .then(function(response){
        if (response.Success){
          dispatch(requestDeleteGroupRegionsSuccess(newRegions));
        }
        else{
          dispatch(requestDeleteGroupRegionsError(response));
        }
      });
  }
}


export { 
  reducerGroupRegions, 
  fetchGroupRegionsByGroupIDs,
  addGroupRegion,
  deleteGroupRegion
};