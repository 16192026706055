const EnumEmployeeRole = {
  Unknown: "unknown",
  AdminPMS: "adminpms",
  TaxiDrivers: "taxidrivers",
  MobileDispatcher: "mobiledispatcher",
  LocalDispatcher: "localdispatcher",
  GroupManager: "groupmanager",
  Paymaster: "paymaster",
  SediManager: "sedimanager",
  SuperDriver: "superdriver",
  SiteManager: "sitemanager",
  HeadManager: "headmanager",
  CarWasher: "carwasher",
  Farmer: "farmer",
  GeoController: "geocontroller",
  GeoControllingObject: "geocontrollingobject",
  AddressEditor: "addresseditor",
  SeniorDispatcher: "seniordispatcher",
  Operator: "operator",
  Logist: "logist",
  Foreman: "foreman",
  TechSupportOperator: "techsupportoperator",
  Cashier: "cashier",
  Mechanic: "mechanic",
  Nurse: "nurse",
  Appraiser: "appraiser"
};

export default EnumEmployeeRole;