import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FaIcon from './FaIcon';

const propTypes = {
  name: PropTypes.string,
  fixedWidth: PropTypes.bool,
  space: PropTypes.bool
};

const defaultProps = {
  name: 'spinner',
  fixedWidth: null,
  space: false
};

class FaSpinner extends Component {
  render() {
    return <FaIcon name={this.props.name} fixedWidth={this.props.fixedWidth} space={this.props.space} spin/>;
  }
}

FaSpinner.propTypes = propTypes;
FaSpinner.defaultProps = defaultProps;

export default FaSpinner;