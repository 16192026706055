import {
  invokeApiLogin,
  invokeApiLogout,
  invokeApiSetPromocode,
  invokeApiSetEmployeeType,
} from "../api/auth";
import { invokeApiGetCards } from "../api/payments/cards";
import UserInfo from "../structs/UserInfo";
import Balance from "../structs/Balance";
import { getJsonFromLocalStorage } from "../utils/storage/localStorage";
import { invokeApiGetBalance } from "../api/bills";
import { func } from "prop-types";

const LOCAL_STORAGE_KEY = "USER";
let userFromLocalStorage = new UserInfo(getJsonFromLocalStorage(LOCAL_STORAGE_KEY));

const FROM_LOCAL_STARAGE = '_FROM_LOCAL_STARAGE_';
userFromLocalStorage[FROM_LOCAL_STARAGE] = 1;

// Reducer
const initialState = {
  user: userFromLocalStorage,
  loading: false,
  error: false,
  setPromocodeLoading: false,
  setPromocodeError: false,
  cards: null,
  getCardsLoading: false,
  getCardsError: false,
};

const ACTION_TYPE_SET_USER = 'SET_USER';

const ACTION_TYPE_SET_BALANCE = 'SET_BALANCE';

const REQUEST_LOGIN_WAIT = 'REQUEST_LOGIN_WAIT';
const REQUEST_LOGIN_SUCCESS = 'REQUEST_LOGIN_SUCCESS';
const REQUEST_LOGIN_FAIL = 'REQUEST_LOGIN_FAIL';

const REQUEST_LOGOUT_WAIT = 'REQUEST_LOGOUT_WAIT';
const REQUEST_LOGOUT_SUCCESS = 'REQUEST_LOGOUT_SUCCESS';
const REQUEST_LOGOUT_FAIL = 'REQUEST_LOGOUT_FAIL';

const REQUEST_SET_PROMOCODE_WAIT = 'REQUEST_SET_PROMOCODE_WAIT';
const REQUEST_SET_PROMOCODE_SUCCESS = 'REQUEST_SET_PROMOCODE_SUCCESS';
const REQUEST_SET_PROMOCODE_FAIL = 'REQUEST_SET_PROMOCODE_FAIL';

const REQUEST_SET_EMPLOYEE_TYPE_SUCCESS = 'REQUEST_SET_EMPLOYEE_TYPE_SUCCESS';

const REQUEST_GET_CARDS_WAIT = 'REQUEST_GET_CARDS_WAIT';
const REQUEST_GET_CARDS_SUCCESS = 'REQUEST_GET_CARDS_SUCCESS';
const REQUEST_GET_CARDS_FAIL = 'REQUEST_GET_CARDS_FAIL';

function reducerUser(state = initialState, action) {
  const newState = reducerUserInternal(state, action);

  try {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newState.user));
  }
  catch (ex) {
    // catch exception
  }

  return newState;
}


function reducerUserInternal(state, action) {
  switch (action.type) {
    case ACTION_TYPE_SET_USER:
      return {
        ...state,
        user: new UserInfo(action.payload),
        loading: false,
        error: false,
      };

    // LOGIN
    case REQUEST_LOGIN_WAIT:
      return {
        ...state,
        user: state.user,
        loading: true,
        error: false,
      };
    case REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        user: new UserInfo(action.payload),
        loading: false,
        error: false,
      };
    case REQUEST_LOGIN_FAIL:
      return {
        ...state,
        user: state.user,
        loading: false,
        error: action.payload,
      };

    // LOGOUT
    case REQUEST_LOGOUT_WAIT:
      return {
        ...state,
        user: state.user,
        loading: true,
        error: false,
      };
    case REQUEST_LOGOUT_SUCCESS:
      return {
        ...state,
        user: new UserInfo(action.payload),
        loading: false,
        error: false,
      };
    case REQUEST_LOGOUT_FAIL:
      return {
        ...state,
        user: state.user,
        loading: false,
        error: action.payload,
      };

    // SET_PROMOCODE
    case REQUEST_SET_PROMOCODE_WAIT:
      return {
        ...state,
        setPromocodeLoading: true,
        setPromocodeError: false,
      };
    case REQUEST_SET_PROMOCODE_SUCCESS:
      let newUser = new UserInfo(state.user);
      newUser.Promocode = action.payload;

      return {
        ...state,
        user: newUser,
        setPromocodeLoading: false,
        setPromocodeError: false,
      };
    case REQUEST_SET_PROMOCODE_FAIL:
      return {
        ...state,
        setPromocodeLoading: false,
        setPromocodeError: action.payload,
      };

    // SET_EMPLOYEE_TYPE
    case REQUEST_SET_EMPLOYEE_TYPE_SUCCESS:
      newUser = new UserInfo(state.user);
      newUser.EmployeeType = action.payload;

      return {
        ...state,
        user: newUser
      };

    // GET_CARDS
    case REQUEST_GET_CARDS_WAIT:
      return {
        ...state,
        getCardsLoading: true,
        getCardsError: false,
      };
    case REQUEST_GET_CARDS_SUCCESS:
      return {
        ...state,
        cards: action.payload,
        getCardsLoading: false,
        getCardsError: false
      };
    case REQUEST_GET_CARDS_FAIL:
      return {
        ...state,
        getCardsLoading: false,
        getCardsError: action.payload,
      };

    case ACTION_TYPE_SET_BALANCE:
      newUser = new UserInfo(state.user);
      newUser.Balance = new Balance(action.payload);

      return {
        ...state,
        user: newUser,
      };

    default:
      return state;
  }
};


function actionLogin(login, pwd, userType) {
  return function (dispatch) {
    dispatch({ type: REQUEST_LOGIN_WAIT });

    invokeApiLogin(login, pwd, userType)
      .then(function (response) {
        if (response.Success) {
          dispatch({ type: REQUEST_LOGIN_SUCCESS, payload: response.LoginInfo });
        }
        else {
          dispatch({ type: REQUEST_LOGIN_FAIL, payload: response });
        }
      });
  }
};


function actionLogout(login, pwd, userType) {
  return function (dispatch) {
    dispatch({ type: REQUEST_LOGOUT_WAIT });

    invokeApiLogout(login, pwd, userType)
      .then(function (response) {
        if (response.Success) {
          dispatch({ type: REQUEST_LOGOUT_SUCCESS, payload: response.LoginInfo });
        }
        else {
          dispatch({ type: REQUEST_LOGOUT_FAIL, payload: response });
        }
      });
  }
};


/**
 * Устанавливает в профиль текущего юзера лицевой счет информатора
 * @param {number} promocode номер лицевого счета информатора
 */
function actionSetPromocode(promocode) {
  return function (dispatch) {
    dispatch({ type: REQUEST_SET_PROMOCODE_WAIT });

    invokeApiSetPromocode(promocode)
      .then(function (response) {
        if (response.Success) {
          dispatch({ type: REQUEST_SET_PROMOCODE_SUCCESS, payload: promocode });
        }
        else {
          dispatch({ type: REQUEST_SET_PROMOCODE_FAIL, payload: response });
        }
      });
  }
};


/**
 * Устанавливает тип текущего сотрудника
 * @param {EnumEmployeeType} employeeType тип сотрудника (Premium)
 */
function actionSetEmployeeType(employeeType) {
  return function (dispatch) {
    invokeApiSetEmployeeType(employeeType)
      .then(function (response) {
        if (response.Success) {
          dispatch({ type: REQUEST_SET_EMPLOYEE_TYPE_SUCCESS, payload: employeeType });
        }
      });
  }
};


/**
 * Загружает банковские карты текущего юзера
 */
function actionGetCards() {
  return function (dispatch) {
    dispatch({ type: REQUEST_GET_CARDS_WAIT });

    invokeApiGetCards()
      .then(function (response) {
        if (response.Success) {
          dispatch({ type: REQUEST_GET_CARDS_SUCCESS, payload: response.Cards });
        }
        else {
          dispatch({ type: REQUEST_GET_CARDS_FAIL, payload: response });
        }
      });
  }
};


/**
 * Загружает банковские карты текущего юзера
 */
function actionGetBalance() {
  return function (dispatch) {
    invokeApiGetBalance()
      .then(function (response) {
        if (response.Success) {
          dispatch({ type: ACTION_TYPE_SET_BALANCE, payload: response.Balance });
        }
      });
  }
};


/**
 * Извлекает из стора информацию о пользователе;
 * если ее нет, то возвращает анонима
 * @param {object} state состояние
 * @returns {UserInfo} профиль юзера
 */
function getUserInfoFromState(state) {
  return new UserInfo((state.user || {}).user);
}


function isInitialUserInfoFromLocalStorage(state){
  const user = (state.user || {}).user;

  return !!user && !!user[FROM_LOCAL_STARAGE];
}


export {
  reducerUser,
  actionLogin,
  actionLogout,
  actionSetPromocode,
  actionSetEmployeeType,
  actionGetCards,
  actionGetBalance,
  ACTION_TYPE_SET_USER,
  getUserInfoFromState,
  isInitialUserInfoFromLocalStorage
};