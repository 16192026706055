import { invokeApi } from "./api";


function invokeApiLogin(login, pwd, userType) {
  return invokeApi("login", { login: login, pwd: pwd, userType: userType });
}


function invokeApiLogout() {
  return invokeApi("logout");
}


function invokeApiSendAuthKey(phone, way, userType, groupID) {
  return invokeApi("get_activation_key", { phone, way, userType, groupID });
}


function invokeApiLoginWithActivationKey(activationKey, userName, userType) {
  return invokeApi("activate", { activationKey, userName, userType });
}


function invokeApiSetPromocode(promocode) {
  return invokeApi("set_promocode", { promocode });
}


function invokeApiSetEmployeeType(employeeType) {
  return invokeApi("set_employee_type", { employeeType });
}


function invokeApiAddAndroidToken(androidtoken) {
  return invokeApi("add_android_token", { androidtoken });
}


function invokeApiAddIPhoneToken(iphonetoken) {
  return invokeApi("add_iphone_token", { iphonetoken });
}


export {
  invokeApiLogin,
  invokeApiLogout,
  invokeApiSendAuthKey,
  invokeApiLoginWithActivationKey,
  invokeApiSetPromocode,
  invokeApiSetEmployeeType,
  invokeApiAddAndroidToken,
  invokeApiAddIPhoneToken
};