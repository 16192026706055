export const dateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.{0,1}\d*))(?:Z|(\+|-)([\d|:]*))?$/;


export function dateReviver(key: any, value: any) : any {
  if (typeof value === "string" && dateFormat.test(value)) {
    return new Date(value);
  }

  return value;
}


/**
 * клонирует объект через JSON parse/stringify, поэтому может успасть при обнаружении циклических ссылок и т.п.
 * @param source {T} исходный объект
 * @returns {T} новый объект
 */
export function cloneDeep<T>(source: T): T {
    // на Android в WebView дает ошибку 'Notification is not defined' 
    //return (new Notification('', { data: source, silent: true }).data) as T;

    return JSON.parse(JSON.stringify(source), dateReviver) as T;
}