import { invokeApi } from "./api";


/**
 * BILL / GET_BILLS
 */
export type BillApiResponse = ApiResponse & {
  Bills?: Bill[]
};


type Bill = {
  ID: string,
  Date: Date,
  PayUrl: string,
  Status: NameTranslate,
  PayeePurse: string,
  Service: NameTranslate,
}


export enum BillStatus {
  New = 'new',
  Invoiced = 'invoiced',
  InProgress = 'inprogress',
  FundsLocked = 'fundslocked',
  Paid = 'paid',
  CancelledTerminalError = 'cancelledterminalerror',
  CancelledAuthorizationError = 'cancelledauthorizationerror',
  Cancelled = 'cancelled',
  CancelledTimeout = 'cancelledtimeout'
}


export type NewBillParameters = {
  service: string, 
  sum: number, 
  comissionSum: number,
  phone?: string,
  successUrl: string,
  cancelUrl: string,
  referenceCardID: number
}


export function invokeApiBill(params: NewBillParameters): Promise<BillApiResponse> {
  return invokeApi("bill", params);
}


export function invokeApiGetBills(id: string | undefined): Promise<BillApiResponse> {
  return invokeApi("get_bills", { id });
}


/**
 * GET_BALANCE 
 */

type Balance = {
  AccountID: number;
  Currency: string;
  Value: number;
  Locked: number;
  Credit: number;
}


export type GetBalanceApiResponse = ApiResponse & {
  Balance?: Balance
};


export function invokeApiGetBalance(accountID: number | undefined) : Promise<BillApiResponse> {
  return invokeApi("get_balance", { accountID });
}


/**
 * GET_PAYMENTSYSTEMS
 */

export type GetPaymentSystemsApiResponse = ApiResponse & {
  PaymentSystems?: Array<PaymentChargeInfo>,
  RecurrentPaymentSystems?: Array<PaymentChargeInfo>
};


export type PaymentChargeInfo = {
  PaymentSystem: NameTranslate,
  Enabled: boolean,
  Customer: number,
  Employee: number,
  Recurrent: boolean,
  Icon: string,
  IconSquare: string,
  Url: string,
  HasTwoStageRecurrentPayments: boolean
}


export function invokeApiGetPaymentSystems() : Promise<GetPaymentSystemsApiResponse> {
  return invokeApi("get_paymentsystems");
}


/**
 * CARDS
 */
export type CardsApiResponse = ApiResponse & {
  Cards?: Card[]
};


export type Card = NameTranslate & {
  ReferenceID: number,
  IsEnabled: boolean,
  Service: NameTranslate,
}


export function invokeApiGetCards(): Promise<CardsApiResponse> {
  return invokeApi("get_cards");
}


export type AddCardParameters = {
  /** сервер сам подберет нужный сервис эквайринга */
  service: 'rfibank', 
  email: string,
  successUrl: string,
  cancelUrl: string
}


export function invokeApiAddCard(params: AddCardParameters): Promise<BillApiResponse> {
  return invokeApi("add_card", params);
}


export function invokeApiDeleteCard(cardID: string, service: string): Promise<ApiResponse> {
  return invokeApi("set_card", {id: cardID, delete: true, service });
}