import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import store from "../store/store";
import AsyncComponent from '../components/AsyncComponent/AsyncComponent';
import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import locale_he from 'react-intl/locale-data/he';
import messages_en from '../translations/locales/en.json';
import messages_de from '../translations/locales/de.json';
import messages_he from '../translations/locales/he.json';
import { IntlProvider, addLocaleData } from 'react-intl';


addLocaleData([...locale_en]);
addLocaleData([...locale_de]);
addLocaleData([...locale_he]);


const messages = {
  'ru': null,
  'en': messages_en,
  'de': messages_de,
  'he': messages_he
}


// ! кэш нужен, чтобы ReactDOM.render() обновлял props ранее созданых компонентов, а не пересоздавал их заново
const asyncComponentsCache = {};


export default function createReactWidget(container, widgetType, widgetProps, callback) {
  let domContainer = container;
  if (typeof domContainer === "string") {
    domContainer = document.getElementById(domContainer);
  }

  const AsyncWidget = asyncComponentsCache[widgetType] || AsyncComponent(() => import( `../components/${widgetType}/${widgetType}`));
  asyncComponentsCache[widgetType] = AsyncWidget;

  widgetProps = widgetProps || {};
  const localeName = widgetProps.lang || 'ru';
  const msg = messages[localeName];


  ReactDOM.render(
    <IntlProvider key={localeName} locale={localeName} messages={msg}>
      <Provider store={store}>
        <AsyncWidget {...widgetProps} />
      </Provider>
    </IntlProvider>,
    domContainer,
    callback);
}


const unmountComponentAtNode = ReactDOM.unmountComponentAtNode;


export { unmountComponentAtNode };